import "./Loadbar.scss";

const Loadbar = (props) => {
    const { 
        distanceState,
        hoverLoadingState,
        hoveredName,
     } = props;

  return (
    <div className={`loadbar__wrapper ${distanceState===2?'active':''}`}>
        <div className='loadbar__outer'>
            <div 
            className={`loadbar__inner`}
            style={{width: hoverLoadingState.current+'%'}}
            />
        </div>
        <div className='loadbar__text'>
            Loading stem: {hoveredName.current}
        </div>
    </div>
  )
}

export default Loadbar