import { useState, useEffect } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import "./Instructions.scss";

const Instructions = (props) => {
  const { helpOpen, setHelpOpen } = props;

  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (windowSize.width <= 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize, isMobile]);

  const handleToggleInstructions = () => {
    setHelpOpen(!helpOpen);
  };
  return (
    <>
      <div
        className={`instructions__blocker ${helpOpen ? "active" : ""}`}
        onClick={handleToggleInstructions}
      />
      <div
        className={`instructions__wrapper ${helpOpen ? "active" : ""}`}
        onClick={handleToggleInstructions}
      >
        <div className="instructions__title">Help</div>
        <button
          className="instructions__close"
          onClick={handleToggleInstructions}
        >
          X
        </button>
        <div className="instructions__inner">
          <div className="instructions__icons-wrapper">
            <div className="instructions__icons-half">
              <div className="instructions__row">
                <div className="instructions__key">
                  {isMobile ? (
                    <span className="instructions__character">&uarr;</span>
                  ) : (
                    <>
                      <span className="instructions__character letter">W</span>
                      <span className="instructions__character arrow">
                        &uarr;
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="instructions__row">
                {isMobile ? (
                  <>
                    <div className="instructions__key">
                      <span className="instructions__character">&larr;</span>
                    </div>
                    <div className="instructions__key">
                      <span className="instructions__character">&darr;</span>
                    </div>
                    <div className="instructions__key">
                      <span className="instructions__character">&rarr;</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="instructions__key">
                      <span className="instructions__character letter">A</span>
                      <span className="instructions__character arrow">
                        &larr;
                      </span>
                    </div>
                    <div className="instructions__key">
                      <span className="instructions__character letter">S</span>
                      <span className="instructions__character arrow">
                        &darr;
                      </span>
                    </div>
                    <div className="instructions__key">
                      <span className="instructions__character letter">D</span>
                      <span className="instructions__character arrow">
                        &rarr;
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="instructions__icons-half">
              <div className="instructions__mouse-outer">
                <div className="instructions__mouse-arrow left">&larr;</div>
                <div className="instructions__mouse-inner" />
                <div className="instructions__mouse-inner right" />
                <div className="instructions__mouse-arrow right">&rarr;</div>
              </div>
            </div>
          </div>
          {isMobile ? (
            <div className="instructions__text">
              Use the arrows to move.
              <br />
              Rotate your device to aim.
              <br />
              <br />
              Collect every stem to unlock a secret opportunity...
              <br />
              <br />
              Use of headphones is recommended.
            </div>
          ) : (
            <div className="instructions__text">
              Use WASD or Arrow Keys to move.
              <br />
              Click and drag to aim.
              <br />
              <br />
              Collect every stem to unlock a secret opportunity...
              <br />
              <br />
              Use of headphones is recommended.
            </div>
          )}
        </div>
      </div>
      <button
        className="instructions__help-button"
        onClick={handleToggleInstructions}
      >
        Help
      </button>
    </>
  );
};

export default Instructions;
