import "./Titles.scss";

const Titles = (props) => {
    const {preloaderVisible} = props;
  return (
    <>
      <div className={`title__wrapper top ${!preloaderVisible?'active':''}`}>
        <div className="title__inner">WET</div>
      </div>
      {/* <div className={`title__wrapper bottom ${!preloaderVisible?'active':''}`}>
        <div className="title__inner">WET</div>
      </div> */}
    </>
  );
};

export default Titles;
