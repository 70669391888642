import CameraRig from "./CameraRig";
import LookControls from "./LookControls";
import WasdControls from "./WASDControls";

import DeviceCameraRig from "./DeviceCameraRig";
import TouchControls from "./TouchControls";

import { useRef, useState, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Raycaster, Vector2 } from "three";

import useWindowSize from "../../hooks/useWindowSize";

const Controls = (props) => {
  const {
    objectsGroup,
    collectables,
    setCollectables,
    // setIsHovered,
    // setHoveredName,
    setDistanceState,
    // setHoverLoadingState,
    forward,
    backward,
    left,
    right,
    deviceControlsAllowed,

    isHovered,
    hoveredName,
    // distanceState,
    hoverLoadingState,


  } = props;
  const { camera } = useThree();
  const raycasterRef = useRef(new Raycaster());

  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (windowSize.width <= 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize, isMobile]);

  useFrame(() => {
    raycasterRef.current.setFromCamera(new Vector2(0, 0), camera);

    let intersections = raycasterRef.current.intersectObjects(
      objectsGroup.current.children
    );

    if (intersections.length) {
      // setIsHovered(true);
      isHovered.current = true;
      const index = intersections[0].object.userData.index;

      if (collectables[index].isComplete === false) {
        if (intersections[0].distance > 15) {
          setDistanceState(1);
          // distanceState.current = 1;
        } else {
          setDistanceState(2);
          // distanceState.current = 2;

          // setHoveredName(collectables[index].name);
          hoveredName.current = collectables[index].name;
          const newCollectables = [...collectables];
          if (newCollectables[index].percentageHovered < 100) {
            newCollectables[index].percentageHovered =
              newCollectables[index].percentageHovered + 1;
            // setHoverLoadingState(newCollectables[index].percentageHovered);
          hoverLoadingState.current = newCollectables[index].percentageHovered
          } else {
            newCollectables[index].isComplete = true;
            // setHoverLoadingState(100);
            hoverLoadingState.current = 100;
            setDistanceState(0);
          // distanceState.current = 0;

          }
          setCollectables(newCollectables);
          // console.log(newCollectables);
          // collectables = newCollectables;
          
        }
      }
    } else {
      // setIsHovered(false);
      isHovered.current = false;
      setDistanceState(0);
      // distanceState.current = 0;

      // setHoverLoadingState(0);
      hoverLoadingState.current = 0;
      // setHoveredName("");
      hoveredName.current = "";


      const newCollectables = [...collectables];
      newCollectables.forEach((collectable) => {
        if (collectable.percentageHovered > 0) {
          collectable.percentageHovered = collectable.percentageHovered - 1;
        }
      });
      setCollectables(newCollectables);
      // collectables = newCollectables;
    }
  });

  if (!isMobile) {
    return (
      <>
        <CameraRig />
        <LookControls />
        <WasdControls />
      </>
    );
  } else {
    return (
      <>
        <DeviceCameraRig 
        {...{
          deviceControlsAllowed
          }}/>
        <TouchControls
          {...{
            forward,
            backward,
            left,
            right,
          }}
        />
      </>
    );
  }
};

export default Controls;
