import { useEffect, useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useLoader, useFrame } from "@react-three/fiber";
import {
  AnimationMixer,
  DoubleSide,
  MeshPhongMaterial,
  TextureLoader,
  EquirectangularReflectionMapping,
  RepeatWrapping,
} from "three";

const Run = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("./assets/models/Run.glb");

  const envMap = useLoader(TextureLoader, "./assets/textures/hdricopy.png");
  envMap.mapping = EquirectangularReflectionMapping;

  const tankMap = useLoader(TextureLoader, "./assets/textures/tank-map.png");
  tankMap.wrapS = tankMap.wrapT = RepeatWrapping;
  tankMap.repeat.y = -1;

  const actions = useRef();
  const [mixer] = useState(() => new AnimationMixer());

  useEffect(() => {
    actions.current = { idle: mixer.clipAction(animations[0], group.current) };
    actions.current.idle.play();
    return () => animations.forEach((clip) => mixer.uncacheClip(clip));
  }, [animations, mixer]);

  useFrame((state, delta) => {
    mixer.update(delta);
  });

  const chrome = new MeshPhongMaterial({
    color: 0x855c3a,
    envMap: envMap,
    reflectivity: 1,
    shininess: 100,
    side: DoubleSide,
  });

  const tank = new MeshPhongMaterial({
    color: 0xffffff,
    map: tankMap,
    shininess: 0,
    reflectivity: 0.01,
    // flipY: false,
    side: DoubleSide,
  });
  return (
    <group ref={group} {...props} dispose={null} position={[0, -32, 0]}>
      <group name="Scene">
        <group name="Armature" rotation={[Math.PI / 2, 0, 0]} scale={40}>
          <skinnedMesh
            name="Genesis8_1FemaleShape"
            geometry={nodes.Genesis8_1FemaleShape.geometry}
            material={chrome}
            skeleton={nodes.Genesis8_1FemaleShape.skeleton}
          />
          <skinnedMesh
            name="Panties_8559Shape"
            geometry={nodes.Panties_8559Shape.geometry}
            material={nodes.Panties_8559Shape.material}
            skeleton={nodes.Panties_8559Shape.skeleton}
          />
          <skinnedMesh
            name="TankTop_23221Shape"
            geometry={nodes.TankTop_23221Shape.geometry}
            material={tank}
            skeleton={nodes.TankTop_23221Shape.skeleton}
          />
          <primitive object={nodes.mixamorigHips} />
        </group>
      </group>
    </group>
  );
};

export default Run;
useGLTF.preload("/Run.glb");
