import { useEffect, useRef, useState, Suspense } from "react";
import { Canvas } from "@react-three/fiber";

import UserInterface from "../components/user-interface/UserInterface";

// import Kim from "../components/3d/models/Kim";
import Run from "../components/3d/models/Run";
import SkyComponent from "../components/3d/scenery/SkyComponent";
import Controls from "../components/controls/Controls";
import Collectables from "../components/3d/models/Collectables";
import Scenery from "../components/3d/scenery/Scenery";

import LoadingManager from "../components/controls/LoadingManager";

const Home = () => {

  const [percentageLoaded, setPercentageLoaded] = useState(0);
  const [isMuted, setIsMuted] = useState(true);
  const objectsGroup = useRef();
  const [skyState, setSkyState] = useState(0);


  const isHovered = useRef(false);
  const hoveredName = useRef('');
  const [distanceState, setDistanceState] = useState(0);
  const hoverLoadingState = useRef(0);


  const [collectables, setCollectables] = useState([
    {
      name: "Bass",
      percentageHovered: 0,
      isComplete: false,
    },
    {
      name: 'Drums',
      percentageHovered: 0,
      isComplete: false,
    },
    {
      name:'Pads',
      percentageHovered: 0,
      isComplete: false,
    },
    {
      name:'Vocals',
      percentageHovered: 0,
      isComplete: false,
    },
    {
      name:'Synth',
      percentageHovered: 0,
      isComplete: false,
    },
  ]);

  useEffect(() => {
    let sum = 0;
    collectables.forEach((collectable) => {
      sum += collectable.isComplete;
    });
    setSkyState(sum);
  }, [collectables]);

  const[forward, setForward] = useState(false);
  const [backward, setBackward] = useState(false);
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);

  const [deviceControlsAllowed, setDeviceControlsAllowed] = useState(false);
  const enterButton = useRef();

  return (
    <>
      <UserInterface
        {...{
          percentageLoaded,
          isMuted,
          setIsMuted,
          collectables,
          isHovered,
          setForward, 
          setBackward, 
          setLeft, 
          setRight,
          deviceControlsAllowed,
          setDeviceControlsAllowed,
          enterButton,
          skyState,

          hoveredName,
          distanceState,
          hoverLoadingState,

        }}
      />
      <div className="canvas__wrapper">
        <Canvas shadows>
          <Suspense fallback={<LoadingManager setPercentageLoaded={setPercentageLoaded} />}>
            <Controls
              {...{
                collectables,
                setCollectables,
                objectsGroup,
                forward, 
                backward, 
                left, 
                right,
                deviceControlsAllowed,

                isHovered,
                hoveredName,
                distanceState,
                setDistanceState,
                hoverLoadingState,

              }}
            />

            <SkyComponent 
            skyState={skyState} />
            <Scenery />
            {/* <Kim skyState={skyState} /> */}
            <Run />
            <group ref={objectsGroup}>
              <Collectables
                {...{
                  collectables,
                  setCollectables,
                  isMuted,
                  enterButton,
                }}
              />
            </group>
          </Suspense>
        </Canvas>
      </div>
    </>
  );
};
export default Home;
